export class Orientation {
    constructor(
        public f0: number,
        public f1: number,
        public f2: number,
        public f3: number,
        public b0: number,
        public b1: number,
        public b2: number,
        public b3: number,
        public start_angle: number,
    ) {
    }
}
